import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { registerLocaleData, ViewportScroller } from '@angular/common'
import localeEs from '@angular/common/locales/es';
import { InitService } from './services/init.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HoverPreviewModule } from './components/commons/hover-preview/hover-preview.module';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade, Virtual } from 'swiper/core';
import { filter } from 'rxjs/operators';
import { Event, Scroll, Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AuthLoginModalModule } from './components/core/auth/auth-login-modal/auth-login-modal.module';
import { AuthSubscribeModalModule } from './components/core/auth/auth-subscribe-modal/auth-subscribe-modal.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { translateBrowserLoaderFactory } from './loaders/translate/translate-browser.loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { RouteService } from './services/route.service';
import { ModalService } from './services/modal.service';
import { NgxMaskModule } from 'ngx-mask';
import { AuthResetPasswordModalModule } from './components/core/auth/auth-reset-password-modal/auth-reset-password-modal.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SubscribeBarModule } from './components/layouts/subscribe-bar/subscribe-bar.module';
import { SsrService } from './services/ssr.service';
import { PixelModule } from 'ngx-pixel';
import { ChangeVersionBarModule } from './components/layouts/change-version-bar/change-version-bar.module';
import { FreeTrialModalModule } from './components/modals/free-trial-modal/free-trial-modal.module';
import { EmailVerificationModalModule } from './components/modals/email-verification-modal/email-verification-modal.module';
import { NewsletterModalModule } from './components/modals/newsletter-modal/newsletter-modal.module';
import { LiveModalModule } from './components/modals/live-modal/live-modal.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

console.info('Version:', environment.version);

registerLocaleData(localeEs);

export function appInit(initService: InitService) {
  return (): Promise<any> => {
    return initService.init();
  }
}

let myImports  = [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  AppRoutingModule,
  HttpClientModule,
  NgbToastModule,
  HoverPreviewModule,
  AuthLoginModalModule,
  AuthResetPasswordModalModule,
  AuthSubscribeModalModule,
  SubscribeBarModule,
  ChangeVersionBarModule,
  FreeTrialModalModule,
  EmailVerificationModalModule,
  NewsletterModalModule,
  LiveModalModule,
  NgxMaskModule.forRoot({
    validation: false,
  }),
  BrowserAnimationsModule,
  TransferHttpCacheModule,
  SweetAlert2Module.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateBrowserLoaderFactory,
      deps: [HttpClient, TransferState]
      // useFactory: (http: HttpClient) => new TranslateHttpLoader(http, environment.langSrcPrefix, '.json?v=' + environment.version),
      // deps: [HttpClient]
    }
  }),
  NgCircleProgressModule.forRoot({
    radius: 100,
    outerStrokeWidth: 5,
    showInnerStroke: false,
    outerStrokeColor: "#EC0024",
    animationDuration: 300,
    showTitle: false,
    showSubtitle: false,
    showUnits: false,
    responsive: true,
    backgroundPadding: 0,
    // outerStrokeLinecap: "square",
  }),
  PixelModule.forRoot({
    enabled: typeof window !== 'undefined' && window.location.href.search('email') === -1 ? true : false,
    pixelId: '1807996522787951'
  })
];

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Virtual]);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: myImports,
  providers: [
    { provide: 'googleTagManagerId', useValue: 'GTM-PX46QKZ6'},
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [InitService], multi: true },
    { provide: LOCALE_ID, useFactory: (translate: TranslateService) => translate.currentLang, deps: [TranslateService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  snapshotPreviousA: ActivatedRouteSnapshot;
  snapshotCurrentA: ActivatedRouteSnapshot

  scrollTopTimeout: any;

  constructor(
    ssrS: SsrService,
    router: Router,
    route: ActivatedRoute,
    routeS: RouteService,
    modalS: ModalService,
    viewportScroller: ViewportScroller,
  ) {

    if (ssrS.isBrowser()) {
      router.events.pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
      ).subscribe(e => {
        clearTimeout(this.scrollTopTimeout);

        this.snapshotPreviousA = this.snapshotCurrentA;
        this.snapshotCurrentA = routeS.getSnapshotLastChild(route.snapshot.root);

        // if (this.snapshotPreviousA?.data.name === 'RegisterPage') {
        //   window.location.href = router.url;
        //   return;
        // }

        if (this.snapshotPreviousA?.data.name !== this.snapshotCurrentA.data.name) {
          modalS.dismissAll();
        }

        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          if (this.snapshotPreviousA?.data.name !== this.snapshotCurrentA.data.name) {
            this.scrollTopTimeout = setTimeout(() => viewportScroller.scrollToPosition([0, 0]));
          }
        }
      });
    }
  }
}
